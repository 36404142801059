<script>
import Layout from "../_layouts/main"
import PageHeader from "@/components/page-header"
import List from './cash.json'
import DatePicker from "@vuepic/vue-datepicker"

export default {
  components: {
    Layout,
    PageHeader,
    DatePicker
  },
  data() {
    return {
      title: this.$t('캐쉬관리'),
      search: {
        groupKey: '',
        type: 'username',
        text: '',
        beginDate: new Date(),
        endDate: new Date()
      },
      sort: {
        field: 'createdAt',
        directive: -1
      },
      list: []
    };
  },
  mounted() {
    this.list = List
  },
  methods: {
    setSort: function(field) {
      let directive
      if (field === this.sort.field) {
        directive = this.sort.directive === -1 ? 1 : -1
      } else {
        directive = -1
      }

      this.sort.field = field
      this.sort.directive = directive
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :search="true" />
    <div class="row">
      <div class="col-md-12 text-end">
        <div class="dropdown-search">
          <form @submit.prevent="searchList">
            <div class="offcanvas offcanvas-end" tabindex="-1" id="search-box" aria-labelledby="search-box-label">
              <div class="offcanvas-header">
                <h5 id="offcanvasRightLabel"><span class="fa fa-search"></span></h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div class="offcanvas-body">
                <div class="form-group mb-3">
                  <DatePicker
                      class="form-control"
                      v-model="search.beginDate"
                      :format="'yyyy-MM-dd HH:mm:ss'"
                      :placeholder="$t('시작일')"
                  ></DatePicker>
                </div>

                <div class="form-group mb-3">
                  <DatePicker
                      class="form-control"
                      v-model="search.endDate"
                      :format="'yyyy-MM-dd HH:mm:ss'"
                      :placeholder="$t('종료일')"
                  ></DatePicker>
                </div>
              </div>
              <div class="p-3">
                <button type="submit" class="btn btn-dark w-100">검색</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-md-3"></div>
              <div class="col-md-4"></div>
              <div class="col-md-4">
                <form>
                  <div class="form-floating">
                    <input type="number" min="1" max="9999999999999" class="form-control" :placeholder="$t('추가') + $t('금액')" />
                    <label class="form-label">{{ $t('추가') + $t('금액') }}</label>
                  </div>
                </form>
              </div>
              <div class="col-md-1">
                <button type="button" class="btn btn-primary w-100 h-100">{{ $t('추가') }}</button>
              </div>
            </div>
            <div id="table-wrapper" class="table-responsive mb-0 rounded-3" style="overflow: auto">
              <table class="table align-middle table-sm table-bordered table-nowrap sticky-table">
                <thead class="table-dark">
                <tr>
                  <th>
                    <a href="javascript:void(0)" @click="setSort('createdAt')">#</a>&nbsp;
                    <template v-if="sort.field === 'createdAt'"><span class="fa fa-sort-amount-down" v-if="sort.directive === -1"></span><span class="fa fa-sort-amount-up" v-if="sort.directive === 1"></span></template>
                  </th>
                  <th>{{ $t('회원정보') }}</th>
                  <th>{{ $t('상위정보') }}</th>
                  <th>{{ $t('카테고리') }}</th>
                  <th>{{ $t('액션') }}</th>
                  <th>{{ $t('데이터') }}</th>
                  <th>{{ $t('메세지') }}</th>
                  <th>{{ $t('결과') }}</th>
                  <th>{{ $t('아이피') }}</th>
                  <th>
                    <a href="javascript:void(0)" @click="setSort('createdAt')">{{ $t('시간') }}</a>&nbsp;
                    <template v-if="sort.field === 'createdAt'"><span class="fa fa-sort-amount-down" v-if="sort.directive === -1"></span><span class="fa fa-sort-amount-up" v-if="sort.directive === 1"></span></template>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="record in list" :key="record.id">
                  <td><button type="button" class="btn btn-secondary btn-sm" @click="openDetail(record)"><span class="mdi mdi-menu"></span></button></td>
                  <td>

                  </td>
                  <td class="text-start">

                  </td>
                  <td></td>
                  <td></td>
                  <td class="text-start">
                    <pre class="rounded-lg" v-highlightjs><code class="javascript" v-html="JSON.stringify(record.data, null, ' ')"></code></pre>
                  </td>
                  <td class="text-start">{{ record.message }}</td>
                  <td>
                    <span class="badge bg-primary" v-if="record.result===true">성공</span>
                    <span class="badge bg-danger" v-if="record.result!==true">실패</span>
                  </td>
                  <td>{{ record.ipAddress }}</td>
                  <td class="small"><span v-b-tooltip.hover data-placement="top" :title="record.createdAt">{{ new Date(record.createdAt).format('YYYY-MM-DD HH:mm:ss') }}</span></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
